export const ArrClone = [
  {
    title: "DASHBOARD",
    href: "/dashboard",
  },
  // {
  //   title: "TRADE",
  //   href: "/trade",
  // },
  {
    title: "AFFILIATE",
    href: "/affiliates",
  },
  {
    title: "STAKING",
    href: "/staking",
  },
  {
    title: "BUY XE",
    href: "/buy-xe",
  },
  {
    title: "WITHDRAW",
    href: "/withdraw",
  },
  // {
  //   title: "TRANSFER",
  //   href: "/transfer",
  // },
  {
    title: "HISTORY",
    href: "/history",
  },
  {
    title: "FARM",
    href: "/farm",
  },

  {
    title: "DOCS",
    href: "https://docs.xfunder.ai/",
  },
];
