import { Box, Flex } from "@raca2022/uikit";
import styled from "styled-components";
import Panner from "../../assets/img/Swap/PannerSwap.png";
import BgPopup from "../../assets/img/Swap/ModalPopup.svg";
import ArrDow from "../../assets/img/Swap/ArrowDow.svg";
import Bg from "../../assets/img/Swap/backGround.svg";
import MobliePanner from "../../assets/img/Swap/mobilePanner.svg";
import PannerText from "../../assets/img/Swap/pannertext.svg";

export const PannerSwap = styled(Flex)`
  background-image: url("${Panner}");
  width: 98.229166666%;
  margin: auto;
  height: 17.326388888vw;
  max-height: 249.5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 2;
  .imgcenter {
    width: 10.451388888%;
    height: 10.451388888vw;
    max-height: 150.5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media only screen and (min-width: 1440px) {
    h1 {
      font-size: 36px;
    }
    :before {
      height: 96px;
    }
  }
  @media only screen and (max-width: 767px) {
    background-image: url("${MobliePanner}");
    height: 32.426666666vw;
    :before {
      width: 40.266666666%;
      height: 11.2vw;
    }
    .imgcenter {
      width: 14.267%;
      height: 10.969vw;
      top: 13.29%;
      right: 6.372%;
      left: auto;
      transform: translateX(0%) translateY(0%);
    }
  }
`;
export const RightPannerGroup = styled(Flex)`
  width: 10.972222222%;
  height: 10.555555555vw;
  max-height: 152px;
  position: absolute;
  top: 8.97703549%;
  right: 1.944444444%;
  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 30.379746835%;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const ItemPopUp = styled(Flex)`
  width: 29.305555555%;
  padding-top: 35px;
  height: 37.638888888vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  margin-top: 2.395833333vw;
  position: relative;
  z-index: 0;
  margin-bottom: 7.388888888vw;
  background: linear-gradient(180deg, rgba(6, 6, 29, 0.6) 0%, rgba(3, 3, 14, 0) 100%);
  border: 2px solid #01fc8d;
  backdrop-filter: blur(10px);
  border-top-left-radius: 24px;
  height: 542px;

  .TextSwap {
    max-height: 31.25px;
  }
  /* @media only screen and (min-width: 1440px) {
    height: 542px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 8vw;
    width: 100%;
    height: 105.639vw;
    background-image: none;
    background-color: black;
    margin-bottom: 0px;
    :before {
      display: none;
    }
  } */
`;
export const InputItem = styled(Flex)`
  width: 91.428571428%;
  height: 4.061340277vw;
  max-height: 58.4833px;
  background: #262626;
  box-shadow: inset 0px 9px 11px #060606;
  border-radius: 2.659574468%/17.543859649%;
  position: relative;
  :after {
    content: "";
    width: 101.063829787%;
    height: 101.063829787%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: linear-gradient(to bottom, #020202, #656565);
    border-radius: 2.659574468%/17.543859649%;
    z-index:-1;
  }
  input{
    width: 37.765957446%;
    height: 1.849534722vw;
    max-height: 26.633px;
    position: absolute;
    left:5.713%;
    background-color: transparent;
    border: none;
    outline: none;
    color: #ffffff;
    font-size:1.388888888vw;
    font-weight: 500;
    top: 50%;
    transform: translateY(-50%);
    font-family: OpenSansMedium;
    ::placeholder{
        color: #ffffff;
        opacity: 1;
    }
  }
  p{
    width: 11.968085106%;
    height: 1.709493055vw;
    font-size:1.388888888vw;
    font-weight:500;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 46.276595744%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: OpenSansMedium;
    :after{
        width: 4.444444444%;
        height: 100%;
        position: absolute;
        content:"";
        top: 0;
        background-color:#828282;
        right: -36.889%;
    }
  }
  @media only screen and (min-width: 1440px){
    input , p{
        font-size: 20px;
    }
  }
  @media only screen and ( max-width: 767px ){
    height: 12.957333333vw;
    input , p {
      height: 5.6vw;
    }
    p , input{
      font-size: 3.733333333vw;
    }
  }
`;

export const RightBox = styled(Flex)`
  width: 39.893617021%;
  height: 2.152777777vw;
  max-height: 31px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  justify-content: flex-end;
  h2 {
    font-size: 1.388888888vw;
    font-weight: 600;
    margin-left: 3.333333333%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18%;
    font-family: OpenSansMedium;
    /* min-width: 80px; */
  }
  /* :after {
    content: "";
    width: 8%;
    height: 0.569444444vw;
    max-height: 8.2px;
    position: absolute;
    background-image: url("${ArrDow}");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    right: 8.666666666%;
    top: 50%;
    transform: translateY(-50%);
  } */
  :nth-child(2) {
    :after {
      display: none;
    }
  }
  @media only screen and (min-width: 1440px) {
    h2 {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 3.733333333vw;
    }
    :after {
      height: 1.569444444vw;
    }
  }
`;

export const ImgBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 1.567131944vw;
  height: 1.567131944vw;
  border-radius: 50%;
  /* background-color: #000000; */
  margin-right: 20px;
  img {
    width: 80%;
    max-width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    height: 30.7667px;
    width: 30.7667px;
  }
  @media only screen and (max-width: 767px) {
    height: 7vw;
    width: 7vw;
  }
`;

export const ImgBoxV2 = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 1.567131944vw;
  height: 1.567131944vw;
  border-radius: 50%;
  /* background-color: #000000; */
  img {
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    height: 30.7667px;
    width: 30.7667px;
  }
  @media only screen and (max-width: 767px) {
    height: 7vw;
    width: 7vw;
  }
`;

export const ItemImgChange = styled(Flex)`
  position: absolute;
  width: 11.111111111%;
  height: 3.255791666vw;
  top: 17.159680638%;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: 100%;
    transform: rotateZ(90deg);
  }
  @media only screen and (max-width: 767px) {
    top: 14.16%;
    height: 10.4vw;
  }
`;
export const Text = styled(Flex)<{ itemTransform?: string }>`
  width: 80.787037037%;
  height: 1.628472222vw;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-${({ itemTransform }) => itemTransform}%);
  span,
  p {
    font-size: 1.388888888vw;
    font-family: OpenSansBold;
  }
  span {
    border-bottom: 1px dashed #828282;
    padding-bottom: 1%;
  }
  @media only screen and (min-width: 1440px) {
    span,
    p {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    p,
    span {
      font-size: 3.733333333vw;
    }
    height: 6.628vw;
  }
`;
export const Button = styled(Flex)`
  width: 69.946759259%;
  height: 4.097222222vw;
  position: absolute;
  bottom: 4.734317343%;
  z-index: 5;
  button {
    background: none;
    border: none;
    out-line: none;
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    svg {
      defs {
        stop {
          stop-color: #671b1b;
          transition: all 0.2s linear;
        }
      }
    }
    :hover {
      svg {
        defs {
          stop {
            stop-color: #ea6e6e;
            transition: all 0.2s linear;
          }
        }
      }
      h3 {
        color: #671b1b;
      }
    }
    :active {
      transform: translateY(1%);
    }
    h3 {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.083333333vw;
      color: #ea6e6e;
      font-family: OpenSansMedium;
      font-weight: 700;
    }
  }
  @media only screen and (min-width: 1440px) {
    height: 59px;
    button {
      h3 {
        font-size: 30px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    height: 13.066666666vw;
    button {
      h3 {
        font-size: 4.066666666vw;
      }
    }
  }
`;

export const UnSwapBtn = styled(Flex)`
  width: 81.042654028%;
  justify-content: space-between;
  padding: 0% 3%;
  position: absolute;
  top: 10.055350553%;
  left: 50%;
  transform: translateX(-50%);
  button {
    color: #e44848;
    background: transparent;
    border: none;
    font-size: 1.111111111vw;
    font-family: OpenSansMedium;
  }
  @media only screen and (min-width: 1440px) {
    button {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    button {
      font-size: 3.8vw;
    }
  }
`;
export const SwapContentTop = styled(Flex)`
  height: 7.291666666vw;
  width: 81.042654028%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  top: 19.280442804%;
  border-radius: 3.508771929%/11.428571428%;
  flex-wrap: wrap;
  justify-content: center;
  p {
    color: #8b9199;
    font-family: OpenSansBold;
    font-size: 0.833333333vw;
    width: 100%;
    text-align: center;
  }
  h1 {
    color: #020d1a;
    font-family: OpenSansSemiBold;
    width: 100%;
    text-align: center;
    font-size: 1.666666666vw;
    font-weight: 500;
  }
  h6 {
    color: #8b9199;
    font-size: 1.111111111vw;
    font-family: OpenSansBold;
    font-weight: 500;
    span {
      font-weight: 400;
      font-family: OpenSansMedium;
    }
  }
  @media only screen and (min-width: 1440px) {
    height: 105px;
    p {
      font-size: 12px;
    }
    h1 {
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 22.292vw;
    p {
      font-size: 3.8vw;
    }
    h1 {
      font-size: 4.666666666vw;
    }
    h6 {
      font-size: 3.8vw;
    }
  }
`;

export const TextDetailSwapGroup = styled(Flex)`
  width: 81.042654028%;
  position: absolute;
  top: 43.081180811%;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: wrap;
  height: 10.138888888vw;
  @media only screen and (min-width: 1440px) {
    height: 146px;
  }
  @media only screen and (max-width: 767px) {
    height: 29.333333333vw;
  }
`;
export const TextDetailSwap = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  height: 1.666666666vw;
  p {
    font-size: 0.972222222vw;
    font-weight: 400;
    color: #a9a9a9;
    font-family: OpenSansMedium;
  }
  h6 {
    margin-left: 7.017543859%;
    font-family: OPENSANSBOLD;
    font-weight: 600;
    color: #f2f2f2;
    font-size: 1.111111111vw;
  }
  @media only screen and (min-width: 1440px) {
    height: 24px;
    p {
      font-size: 14px;
    }
    h6 {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 6.4vw;
    p {
      font-size: 3.8vw;
    }
    h6 {
      font-size: 4.08vw;
    }
  }
`;

export const ImgSwap = styled(Flex)`
  width: 50%;
  height: 100%;
  justify-content: flex-end;
  div,
  img {
    height: 100%;
  }
`;
