import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const TwitterIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2.1256C19.251 2.49317 18.4565 2.73443 17.643 2.84134C18.4998 2.27316 19.1407 1.37924 19.4462 0.326277C18.6408 0.855877 17.7595 1.22887 16.8404 1.42911C15.5677 -0.0663803 13.5479 -0.431163 11.9123 0.539108C10.2766 1.50937 9.43145 3.57371 9.85019 5.57574C6.55044 5.39279 3.47605 3.6665 1.39247 0.826674C0.303637 2.90365 0.859543 5.56024 2.6623 6.89499C2.01079 6.87245 1.37359 6.67759 0.803936 6.32669C0.803936 6.34558 0.803936 6.36301 0.803936 6.38406C0.804188 8.54744 2.18037 10.4112 4.09476 10.8408C3.49065 11.0233 2.85681 11.0499 2.24197 10.9185C2.77875 12.7702 4.31914 14.039 6.07477 14.0756C4.62146 15.3394 2.82643 16.0248 0.978688 16.0213C0.651616 16.0215 0.324812 16.0003 0 15.9577C1.8762 17.2933 4.05991 18.0022 6.29018 18C13.8374 18 17.9646 11.0736 17.9646 5.06698C17.9646 4.86968 17.9608 4.67347 17.9531 4.47834C18.7564 3.83525 19.4496 3.03851 20 2.1256Z"
        fill="white"
      />
    </Svg>
  );
};

export default TwitterIcon;
