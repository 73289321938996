import { Flex, Box, Text } from "@raca2022/uikit";
import styled from "styled-components";
import { Button } from "../Button";

export const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    #06061d,
    #06061d,
    #06061d,
    #06061d,
    #06061d,
    transparent
  );
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;    
  /* background: rgba(28, 28, 28, 0.9); */
  z-index: 90;

  /* backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  border-radius: 4px;
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, backdrop-filter, -webkit-backdrop-filter; */

  /* &::before {
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
    border-radius: 4px;
  } */

  .fl-left {
    flex-basis: 100%;
  }

  @media only screen and (max-width: 1200px) {
    padding: 18px 14px;
    background: linear-gradient(
      #06061d,
      #06061d,
      #06061d,
      #06061d,
      transparent
    );
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ButtonConect = styled(Button)`
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #01ec85;
  border-radius: 16px 2px 2px 2px;
  text-transform: uppercase;
  font-family: ClashDisplay;
  font-size: 16px;
  line-height: 180%;
  font-weight: 500;

  :hover {
    color: #01fe8f;
    background: transparent;
    border: 2px solid #01ec85;
  }
`;

export const WrapperMobile = styled.div`
  .bt-mobile {
    background: transparent;
    height: auto;
    padding-right: 10px;
    box-shadow: none !important;
  }
`;

export const BoxMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
`;

export const MenuLangdingWapper = styled.div<{ isScroll?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;
  z-index: 90;
  height: 90px;
  background: ${({ isScroll }) =>
    isScroll
      ? "linear-gradient(rgb(6, 6, 29), rgb(6, 6, 29), rgb(6, 6, 29), rgb(6, 6, 29), rgb(6, 6, 29), transparent)"
      : "rgba(0,0,0,0)"};
  @media only screen and (min-width: 1440px) {
    background: transparent;
  }
  @media only screen and (max-width: 1200px) {
    padding: 18px 14px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
  button {
    width: 159px;
    height: 48px;
    background: rgba(255, 255, 255, 0.1);
    border: 1.5px solid #01ec85;
    border-radius: 16px 2px 2px 2px;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #ffffff;
    font-family: ClashDisplayMedium;
    :hover {
      color: #01fe8f;
      background: transparent;
      border: 2px solid #01ec85;
    }
  }
`;
