import styled from "styled-components";
import notaImg from "./no-data.png";

const Table = ({ data, keyObj, loadingTable }: any) => {
  return (
    <TableContainer className="Tabble-Wapper">
      <table>
        <thead>
          <tr>
            {keyObj &&
              keyObj.map((item: any, index: number) => {
                return <th key={`${item.th}${index}`}>{item.th}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          {data && data.length ? (
            <>
              {keyObj &&
                data.map((_item: any, index: number) => (
                  <tr key={`tr${index}`}>
                    {keyObj.map((key: any, _index: number) => (
                      <td key={`td${index}${_index}`}>
                        {key.th === "No"
                          ? index + 1
                          : key.callback
                          ? key.callback(_item, index)
                          : _item[key.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              {loadingTable && (
                <tr>
                  <td colSpan={keyObj.length}>
                    <div className="row-nodata">
                      <img src={notaImg} />
                      <p>No data</p>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td colSpan={keyObj.length}>
                <div className="row-nodata">
                  <img src={notaImg} />
                  <p>No data</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};
export default Table;

export const TableContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #3a3a3a;
  background: #28282a;
  padding: 0px 14px;
  overflow-x: auto;
  .row-nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      max-width: 100px;
    }
  }
  table {
    width: 100%;
    min-width: max-content;
    th,
    td {
      padding: 15px;
    }
    th {
      text-align: left;
      color: rgba(226, 232, 255, 0.7);
      font-size: 14px;
      font-weight: 300;
      line-height: 110%; /* 15.4px */
    }
    td {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%; /* 15.4px */
      letter-spacing: 0.14px;
    }
    tr {
      th,
      td {
        &:last-child {
          text-align: center;
        }
      }
    }
  }
`;
