import React, { useState } from "react";
import { Flex, Box, Text } from "@raca2022/uikit";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ActiveIcon } from "../../Svg";
import { ArrClone } from "./config";

const MenuList = () => {
  const [isArr] = useState(ArrClone);

  return (
    <WrapperList>
      {isArr.map((_item, _index) => {
        return (
          <>
            {_item.title === "DOCS" ? (
              <a href={_item.href} target="_blank">
                <Flex>
                  {/* <Box pr={["5px", "5px", "5px", "10px"]}>
                <Box className="activeIcons">
                  <ActiveIcon width="16px" />
                </Box>
              </Box> */}
                  <Item as="h1">{_item.title}</Item>
                </Flex>
              </a>
            ) : (
              <NavLink to={_item.href}>
                <Flex width={'max-content'}>
                  {/* <Box pr={["5px", "5px", "5px", "10px"]}>
                <Box className="activeIcons">
                  <ActiveIcon width="16px" />
                </Box>
              </Box> */}
                  <Item as="h1">{_item.title}</Item>
                </Flex>
              </NavLink>
            )}
          </>
        );
      })}
    </WrapperList>
  );
};

export default React.memo(MenuList);

const WrapperList = styled(Flex)`
  gap: 50px;
  padding-left: 28px;
  flex-basis: 100%;
  justify-content: center;

  .activeIcons {
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease, visibility 500ms ease;
    animation: spinss 10s linear infinite;

    @-moz-keyframes spinss {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spinss {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spinss {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    gap: 20px;
    justify-content: flex-start;
    padding-left: 0;
  }

  a.active {
    h1 {
      color: #4eff00 !important;
    }

    .activeIcons {
      opacity: 1;
      visibility: visible;
    }
  }

  /* @media only screen and (max-width: 1200px) {
    gap: 10px;
  }

  @media only screen and (max-width: 1040px) {
    .activeIcons {
      display: none;
    }
  } */
`;

const Item = styled(Text)`
  font-family: ClashDisplayRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #ffffff;
  cursor: url("/assets/cursor/cursor.svg") 15 15, move !important;
  transition: color 0.2s ease;
  padding-top: 30px;
  padding-bottom: 29px;
  text-transform: uppercase;

  :hover {
    color: #01fe8f;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 12px;
  }
`;
