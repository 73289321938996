import {
  InputItem,
  ListCoins,
  InputRight,
  Coin,
  WrapperNew,
  BannerTop,
  TitleTop,
  BannerBlack,
  BoxBody,
  BoxCard,
  Card,
  TitleSmall,
  ButtonNew,
} from "../Withdraw/style";
import {
  BoxStyled1,
  BuyContainer,
  BuyWapper,
  TextDes,
  TextMax,
  TextTile,
} from "./style";
import { Container } from "../../components/Container";
import { RightPannerGroup, RightBox, ImgBox, Text } from "../Swap/style";
import { Flex } from "@raca2022/uikit";
import LogoInvest from "../../assets/DashBoard/usdt.svg";
import XGreen from "../../assets/Footer/X.svg";
import arrow from "../../assets/Home/arrow-3.svg";
import { useEffect, useMemo, useState } from "react";
import web3 from "web3";
import { ADDRESS_USDT, ADDRESS_FBD } from "../../constants";
import abiUSDT from "../../config/abi/abiUSDT.json";
import abiBuyTestnet from "../../config/abi/abiBuyTestnet.json";
import abiTokentUsdtTestnet from "../../config/abi/abiTokentUsdtTestnet.json";
import abiFBD from "../../config/abi/abiFBD.json";
import { useActiveWeb3React } from "../../hooks";
import { useModal } from "@raca2022/uikit";
import { ConnectWallet } from "../../components/Connect";
import toast from "react-hot-toast";
import Table from "../../components/tableCommon";
import TableMobile from "../../components/tableCommon/tableMobile";
import axios from "axios";

const BuyToken = () => {
  const w3: any = window.ethereum ? new web3(window.ethereum) : new web3("");
  const contract = new w3.eth.Contract(abiUSDT, ADDRESS_USDT[56]);
  const contractBuyTestnet = new w3.eth.Contract(
    abiFBD,
    "0x79c28Eb043d5F0Ffb99e93C90c95c037C1a248d7"
  );
  const [ButtonText, setButtonText] = useState("");
  const [usdtBalance, setUsdtBalance] = useState(0);
  const { account } = useActiveWeb3React();
  const [onModalCustom] = useModal(<ConnectWallet />);
  const [reverXe, setReverXe] = useState(0.0);
  const [amount, setAmount] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [round, setRound] = useState(0);
  const [loading, setLoading] = useState<any>(false);
  const [timeStartRound, setTimeStartRound] = useState(0);
  const [price, setPrice] = useState(0);
  const [balanceRound, setBalanceRound] = useState<any>();

  useMemo<any>(() => {
    if (account && contract) {
      setButtonText("BUY  XE  TOKEN");
      // contract.methods.balanceOf(account).call((error: any, balance: any) => {
      contract.methods.balanceOf(account).call((error: any, balance: any) => {
        if (!error) {
          let _balance: any = web3.utils.fromWei(
            web3.utils.toBN(balance),
            "ether"
          );
          let bl = Math.round(parseFloat(_balance) * 10000) / 10000;
          setUsdtBalance(bl);
        }
      });
    } else {
      setButtonText("Connect Wallet");
    }
  }, [account]);
  const handleGetRound = () => {
    contractBuyTestnet.methods
      .round()
      .call()
      .then((res: any) => {
        setRound(res);
        // console.log('resRound' , res);
        handleGetInfoRound(res);
        handleGetTotalBalanceInRound();
      })
      .catch((error: any) => {
        // console.log('error', error);
      });
  };
  const handleGetInfoRound = (round: any) => {
    contractBuyTestnet.methods
      .infoRounds(round)
      .call(account)
      .then((res: any) => {
        let minRage: any = web3.utils.fromWei(
          web3.utils.toBN(res.amountRange),
          "ether"
        );
        let min = Math.round(parseFloat(minRage) * 10000) / 10000;
        let price: any = web3.utils.fromWei(
          web3.utils.toBN(res.priceSaleToken),
          "ether"
        );
        let _price = Math.round(parseFloat(price) * 10000) / 10000;
        setMinAmount(min);
        setTimeStartRound(res.timeStart);
        setPrice(_price);
      });
  };
  const handleGetTotalBalanceInRound = () => {
    // console.log("resi");
    contractBuyTestnet.methods
      .tokenXE()
      .call(account)
      .then((resi: any) => {
        // console.log("resi" , resi);
        contractBuyTestnet.methods
          .getBalanceTokenOnContract(resi)
          .call(account)
          .then((res: any) => {
            // console.log("resi123", res);
            let minRage: any = web3.utils.fromWei(
              web3.utils.toBN(res),
              "ether"
            );
            let min = Math.round(parseFloat(minRage) * 10000) / 10000;
            // console.log("min", min);
            setBalanceRound(min);
          });
      })
      .catch((err: any) => {
        // console.log('resErr' , err);
      });
  };
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    let timeLeft = {};
    const difference = timeStartRound - now;
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [timeStartRound]);
  // const handleGetTokentUsdt = () => {
  //   contractBuyTestnet.methods
  //     .tokenUsdt()
  //     .call(account)
  //     .then((res: any) => {
  //       setContractUsdt(new w3.eth.Contract(abiTokentUsdtTestnet, res));
  //     });
  // };
  const handleBuy = () => {
    if (amount < minAmount) {
      toast.error(`Minimum purchase of ${minAmount} USDT.`);
      return null;
    }
    if (reverXe > balanceRound) {
      toast.error(`Not enough XE tokens to make the transaction.`);
      return null;
    }
    if (amount > usdtBalance) {
      toast.error(`You don't have sufficient balance.`);
      return null;
    }
    setLoading(true);
    let amountApprove = balanceRound > usdtBalance ? usdtBalance : balanceRound;
    const amountAprove = web3.utils.toWei(amountApprove.toString());
    contract.methods
      .approve(ADDRESS_FBD[56], amountAprove)
      .send({ from: account, gas: 350000 })
      .then((receipt: any) => {
        toast.success("approve successfully");
        const amountTemp = web3.utils.toWei(amount.toString());
        contractBuyTestnet.methods
          .buyICO(round, amountTemp)
          .send({
            from: account,
            gas: 350000,
          })
          .then((receipt: any) => {
            toast.success("successfully");
            // setEvents([]);
            handleGetTotalBalanceInRound();
            handleGetBlock();
            contract.methods
              .balanceOf(account)
              .call((error: any, balance: any) => {
                if (!error) {
                  let _balance: any = web3.utils.fromWei(
                    web3.utils.toBN(balance),
                    "ether"
                  );
                  let bl = Math.round(parseFloat(_balance) * 10000) / 10000;
                  setUsdtBalance(bl);
                }
              });
            loadEvents();
            setLoading(false);
          })
          .catch((error: any) => {
            toast.error(error?.message);
            setLoading(false);
          });
      })
      .catch((error: any) => {
        setLoading(false);
        toast.error(error?.message);
      });
  };

  useEffect(() => {
    if (contractBuyTestnet) {
      handleGetRound();
      // handleGetTokentUsdt();
    }
  }, [contractBuyTestnet]);

  const handleClickBtn = () => {
    if (!account) {
      onModalCustom();
    } else {
      handleBuy();
    }
  };

  const [events, setEvents] = useState<any>([]);
  const [listTxh, setListTxh] = useState<any>([]);
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  const handleGetBlock = async () => {
    const latestBlockNumber = await w3.eth.getBlockNumber();
    setCurrentBlock(latestBlockNumber);
  };

  useEffect(() => {
    handleGetBlock();
  }, []);
  const GetTransactionByHash = async (txh: any) => {
    try {
      const responseGetTransactionByHash = await axios.post(
        "https://bsc.blockpi.network/v1/rpc/db86a74c12631cc8f1d63ff412a745fa72046637",
        {
          jsonrpc: "2.0",
          method: "eth_getTransactionReceipt",
          params: [txh],
          id: 56,
        }
      );
      return responseGetTransactionByHash?.data?.result;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const loadEvents = async () => {
    setLoadingTable(true);
    if(events.length > 10 || currentBlock <  37646258){
      setLoadingTable(true)
      return;
    }
    try {
      const responseGetLogs = await axios.post(
        "https://bsc.blockpi.network/v1/rpc/db86a74c12631cc8f1d63ff412a745fa72046637",
        {
          jsonrpc: "2.0",
          method: "eth_getLogs",
          params: [
            {
              fromBlock: currentBlock - 3000 > 37646258 ? currentBlock - 3000 : 37646258,
              toBlock:currentBlock,
              address: "0x79c28Eb043d5F0Ffb99e93C90c95c037C1a248d7",
            },
          ],
          id: 56,
        }
      );
      console.log("responseGetLogs", responseGetLogs);
      let ListEvent = await responseGetLogs?.data?.result.map(
        (item: any, index: number) => {
          if (item.transactionHash && index <= 9) {
            return item.transactionHash;
          }
        }
      );
      let result: any = [];
      for (const txh of ListEvent) {
        const transactionResult = await GetTransactionByHash(txh);
        result = [...result, transactionResult];
      }
      console.log("result", result);
      setListTxh([...listTxh , ...ListEvent.slice().reverse()]);
      setEvents([...events , ...result.slice().reverse()]);
      setCurrentBlock(currentBlock - 3000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    loadEvents();
  }, [currentBlock]);
  console.log('evnet' , events);
  
  return (
    <BuyWapper>
      <BoxBody>
        <Container>
          <BoxCard>
            <Card>
              <TitleSmall>Buy XE Token</TitleSmall>
              <BoxStyled1 style={{ marginTop: "27px" }}>
                <p>Total supply</p>
                <span>{balanceRound} XE</span>
              </BoxStyled1>
              <BoxStyled1
                style={{
                  paddingBottom: "16px",
                  borderBottom: "1px solid #006438",
                  marginBottom: "46px",
                }}
              >
                <p>Price</p>
                <span>{price || "--"}$</span>
              </BoxStyled1>
              <InputItem mb="22px" text={"Amount"}>
                <TextTile>
                  <span>Your Balance:</span> &nbsp;$ {usdtBalance} USD
                </TextTile>
                <input
                  type="number"
                  placeholder="0.00"
                  value={amount || ""}
                  onChange={(e) => {
                    setAmount(Number(e.target.value));
                    price && setReverXe(Number(e.target.value) / price);
                  }}
                />
                <TextMax>
                  <span>MAX</span>
                </TextMax>
              </InputItem>
              <TitleSmall bg={arrow}></TitleSmall>
              <InputItem text={"Received"} style={{ marginTop: "27px" }}>
                <p
                  style={{ opacity: 0.5, justifyContent: "flex-start" }}
                >{`${reverXe.toFixed(0)}`}</p>
                <RightBox>
                  <ImgBox>
                    <img src={XGreen} alt="" />
                  </ImgBox>
                </RightBox>
              </InputItem>
              <Flex justifyContent="center" width="100%" pt="50px">
                <abbr>
                  <ButtonNew
                    onClick={
                      !loading &&
                      !timeLeft.days &&
                      !timeLeft.hours &&
                      !timeLeft?.minutes &&
                      !timeLeft?.seconds &&
                      handleClickBtn
                    }
                  >
                    {loading
                      ? "Loading..."
                      : !timeLeft.days &&
                        !timeLeft.hours &&
                        !timeLeft?.minutes &&
                        !timeLeft?.seconds
                      ? ButtonText
                      : `${timeLeft?.days || 0}D ${timeLeft?.hours || 0}H ${
                          timeLeft?.minutes || 0
                        }M ${timeLeft?.seconds || 0}S`}
                  </ButtonNew>
                </abbr>
              </Flex>
            </Card>
          </BoxCard>
          <BuyContainer>
            <h1> BUY TOKEN HISTORY </h1>
            <Table
              data={events}
              loading={loadingTable}
              keyObj={[
                // {
                //   th: "Time",
                //   key: "",
                //   callback: (data: any) => {
                //     const dateObject = new Date(
                //       Number(data?.returnValues?.TimeBuy) * 1000
                //     );
                //     const formattedDate =
                //       dateObject.toLocaleDateString("en-US");
                //     return formattedDate;
                //   },
                // },
                {
                  th: "Address",
                  key: "Address",
                  callback: (data: any) => {
                    return (
                      <>{`${data?.from?.substr(0, 4)}...${data?.from?.substr(
                        data?.from?.length - 4
                      )}`}</>
                    );
                  },
                },
                {
                  th: "T x H",
                  key: "transactionHash",
                  callback: (data: any, index: any) => {
                    return (
                      <>{`${listTxh[index]?.substr(0, 4)}...${listTxh[
                        index
                      ]?.substr(listTxh[index]?.length - 4)}`}</>
                    );
                  },
                },
                {
                  th: "Amount Token Buy",
                  key: "Amount",
                  callback: (data: any) => {
                    return (
                      <>
                        {data?.logs[0]?.data
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              parseInt(data?.logs[0]?.data, 16) / 10 ** 18
                            )
                          : "--"}
                      </>
                    );
                  },
                },
                {
                  th: "Amount Token Received",
                  key: "Amount",
                  callback: (data: any) => {
                    return (
                      <>
                        {data?.logs[2]?.data
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                              .format(
                                parseInt(data?.logs[2]?.data, 16) / 10 ** 18
                              )
                              .split("$")[1]
                          : "--"}{" "}
                        XE
                      </>
                    );
                  },
                },
                {
                  th: "Status",
                  key: "Status",
                  callback: (data: any) => {
                    return (
                      <TextDes>
                        {data?.status === "0x1" ? "Success" : ""}
                      </TextDes>
                    );
                  },
                },
              ]}
            />

            <TableMobile
              data={events}
              keyList={{
                title: [
                  {
                    name: "Amount Buy",
                    value: "Amount",
                    callback: (data: any) => {
                      return (
                        <>
                          {data?.logs[0]?.data
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              parseInt(data?.logs[0]?.data, 16) / 10 ** 18
                            )
                          : "--"}
                        </>
                      );
                    },
                  },
                  {
                    th: "Amount Received",
                    key: "Amount Received",
                    callback: (data: any) => {
                      return (
                        <>
                          {data?.logs[2]?.data
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                                .format(
                                  parseInt(data?.logs[2]?.data, 16) / 10 ** 18
                                )
                                .split("$")[1]
                            : "--"}{" "}
                          XE
                        </>
                      );
                    },
                  },
                ],
                body: [
                  {
                    name: "Address",
                    value: "Address",
                    callback: (data: any) => {
                      return (
                        <>{`${data?.from?.substr(0, 4)}...${data?.from?.substr(
                          data?.from?.length - 4
                        )}`}</>
                      );
                    },
                  },
                  {
                    name: "T x H",
                    value: "transactionHash",
                    callback: (data: any) => {
                      return (
                        <>{`${data?.transactionHash?.substr(
                          0,
                          4
                        )}...${data?.transactionHash?.substr(
                          data?.transactionHash?.length - 4
                        )}`}</>
                      );
                    },
                  },
                ],
              }}
              collaps={false}
            />
          </BuyContainer>
        </Container>
      </BoxBody>
    </BuyWapper>
  );
};
export default BuyToken;
