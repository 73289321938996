import { Box, Flex } from "@raca2022/uikit";
import styled from "styled-components";
import { Button } from "../../components/Button";

import MobliePanner from "../../assets/img/Withdraw/MobilePanner.svg";
import Panner from "../../assets/img/Withdraw/pannerWithdraw.png";
import Bg from "../../assets/img/Swap/backGround.svg";
import BgPopup from "../../assets/img/Swap/ModalPopup.svg";
import PannerText from "../../assets/img/Withdraw/withdrawText.svg";

import BannerWithDraw from "../../assets/withdraw/BannerTop.png";
import BannerMobile from "../../assets/withdraw/bannerMobile.png";
import BannerCard from "../../assets/withdraw/BannerCard.png";
import arrowDubble from "../../assets/withdraw/arrowDubble.png";

export const PannerWithdraw = styled(Flex)`
  background-image: url("${Panner}");
  width: 98.229166666%;
  margin: auto;
  height: 17.361111111vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  max-height: 250px;
  z-index: 2;
  .imgcenter {
    width: 10.451388888%;
    height: 10.451388888vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  // :before{
  //   content: "";
  //   width: 38.263888888%;
  //   height: 6.66666vw;
  //   background-image: url('${PannerText}');
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position: center center;
  //   position: absolute;
  //   left:3.5%;
  //   bottom:5%;
  // }
  @media only screen and (min-width: 1440px) {
    h1 {
      font-size: 36px;
    }
    :before {
      height: 96px;
    }
  }
  @media only screen and (max-width: 767px) {
    background-image: url("${MobliePanner}");
    height: 32.426666666vw;
    :before {
      width: 65.682666666%;
      height: 11.2vw;
    }
    .imgcenter {
      width: 14.267%;
      height: 10.969vw;
      top: 13.29%;
      right: 6.372%;
      left: auto;
      transform: translateX(0%) translateY(0%);
    }
  }
`;
export const ItemPopUp = styled(Flex)`
  width: 29.305555555%;
  padding-top: 2.395833333vw;
  height: 37.638888888vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  margin-top: 2.395833333vw;
  position: relative;
  z-index: 0;
  background-image: url("${Bg}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 7.388888888vw;
  :before {
    content: "";
    position: absolute;
    width: 241.542288557%;
    height: 34.722222222vw;
    background-image: url("${BgPopup}");
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -5;
  }
  // :after {
  //   content: "";
  //   width: 100%;
  //   height: 2.395833333vw;
  //   position: absolute;
  //   background: linear-gradient(rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%);
  //   top: 0%;
  //   left: 0%;
  // }
  .Withdraw-Title1,
  .Withdraw-Title2 {
    font-size: 1.111111111vw;
    position: absolute;
    top: 1.752767527%;
    left: 4.285714286%;
    z-index: 2;
    color: #cfd9e6;
    font-weight: 500;
    font-family: OpenSansMedium;
  }
  .Withdraw-Title2 {
    top: 25.047970479%;
  }
  .Text-fee {
    position: absolute;
    top: 58%;
  }
  @media only screen and (min-width: 1440px) {
    height: 542px;
    .Withdraw-Title1,
    .Withdraw-Title2 {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    margin-top: 8vw;
    width: 100%;
    background-image: none;
    background-color: black;
    height: 105.639vw;
    margin-bottom: 0px;
    :before {
      display: none;
    }
    .Withdraw-Title1,
    .Withdraw-Title2 {
      font-size: 4.26vw;
      top: -4.752767527%;
    }
    .Withdraw-Title2 {
      top: 22.047970479%;
    }
  }
`;

export const InputItem = styled(Flex)<{text?:string}>`
width: 376px;
height: 56px;
background: #1D1D27;
border-radius: 10px;
position: relative;
margin-top: 70px;
border: 1px solid #D0D5DD;
:after {
  position: absolute;
  width: 50%;
  height: 20px;
  ${({text})=> text ? `content: '${text}'` : 'content: "From Wallet"'};
  top: 0px;
  left: 0px;
  transform: translateY(calc(-100% - 6px));
  font-family: ClashDisplayMedium;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}
h3{
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  font-weight: 600;
  font-family: ClashDisplayMedium;
  span{
    font-weight: 400;
  }
  position: absolute;
  height: 20px;
  top: 0px;
  right: 0px;
  transform: translateY(calc(-100% - 6px));
}
input{
  width: 37.765957446%;
  height: 1.849534722vw;
  max-height: 26.633px;
  position: absolute;
  left:5.713%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  font-size:1.388888888vw;
  font-weight: 500;
  top: 50%;
  transform: translateY(-50%);
  font-family: OpenSansBold;
  ::placeholder{
      color: #ffffff;
      opacity: 1;
  }
}
p{
  width: 12.968085106%;
  height: 1.709493055vw;
  font-size:1.388888888vw;
  font-weight:500;
  position: absolute;
  top: 50%;
  left: 5.713%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: OpenSansBold;
  /* :after{
      width: 4.444444444%;
      height: 100%;
      position: absolute;
      content:"";
      top: 0;
      background-color:#828282;
      right: -8.888888888%;
  } */
}
:nth-child(4){
    position: relative;
    /* top: 31.32103321%; */
    margin-top: 27px;
    h6{
        font-size: 1.388888888vw;
        position: absolute;
        right: 4.255319148%;
        top: 51%;
        transform: translateY(-50%);
        font-family: OpenSansBold;
    }
    :after{
      position: absolute;
      width: 50%;
      height: 20px;
      ${({text})=> text ? `content: '${text}'` : 'content: "Amount"'};
      top: 0px;
      left: 0px;
      transform: translateY(calc(-100% - 6px));
      font-family: ClashDisplayMedium;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
}
@media only screen and (min-width: 1440px){
  input , p{
      font-size: 20px;
  }
  :nth-child(4){
    h6{
        font-size: 20px;
    }
}
}
@media only screen and ( max-width: 767px ){
  height: 12.957333333vw;
  p , input{
    font-size: 3.733333333vw;
    height: 5.6vw;
  }
  :nth-child(4){h6{
    font-size: 4.266666666vw;
  }}
}
@media only screen and ( max-width: 560px ){
  width: 100%;
}
`;

// export const Button = styled(Flex)`
//   width: 69.946759259%;
//   height: 4.097222222vw;
//   position: absolute;
//   bottom: 17.095940959%;
//   z-index: 5;
//   button {
//     background: none;
//     border: none;
//     out-line: none;
//     position: relative;
//     z-index: 5;
//     width: 100%;
//     height: 100%;
//     svg {
//       defs {
//         stop {
//           stop-color: #671b1b;
//           transition: all 0.2s linear;
//         }
//       }
//     }
//     :hover {
//       svg {
//         defs {
//           stop {
//             stop-color: #ea6e6e;
//             transition: all 0.2s linear;
//           }
//         }
//       }
//       h3 {
//         color: #671b1b;
//       }
//     }
//     :active {
//       transform: translateY(1%);
//     }
//     h3 {
//       position: absolute;
//       width: 100%;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       font-size: 1.388888888vw;
//       color: #ea6e6e;
//       font-family: OpenSansBold;
//       font-weight: 700;
//     }
//   }
//   @media only screen and (min-width: 1440px) {
//     height: 59px;
//     button {
//       h3 {
//         font-size: 20px;
//       }
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     height: 13.066666666vw;
//     button {
//       h3 {
//         font-size: 5.066666666vw;
//       }
//     }
//   }
// `;

export const ListCoins = styled(Box)`
  position: absolute;
  width: 100%;
  background: #262626;
  height: fit-content;
  top: 110%;
  left: 0px;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.2s ease;
  z-index: 5;
`;

export const Coin = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  p {
    position: relative;
    width: auto;
    height: auto;
    top: initial;
    transform: initial;
  }
  img {
    margin-right: 26px;
    height: 36%;
  }
`;

export const InputRight = styled(Flex)`
  width: 50%;
  max-width: 118px;
  height: 30px;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: black;
    :nth-child(2) {
      width: 10px;
      height: 10px;
      background-color: transparent;
      margin-left: 10px;
    }
  }
  p {
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -2%;
    font-weight: 600;
    font-family: RALEWAYSEMIBOLD;
  }
`;

export const WrapperNew = styled.div`
  width: 100%;
  height: 100%;
  /* min-height: 80vh; */
`;

export const BannerBlack = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #000000 0%, #000000 99.04%);
  padding: 40px 0;
  position: relative;

  :after {
    position: absolute;
    content: "";
    top: -25%;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);
  }

  :before {
    position: absolute;
    content: "";
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 50;
    /* transform: rotate(180deg); */
  }

  @media only screen and (max-width: 560px) {
    padding: 10px;
    :after,
    :before {
      height: 40px;
    }

    :after {
      top: -18%;
    }

    :before {
      bottom: -18%;
    }
  }
`;
export const BannerTop = styled.div`
  width: 100%;
  height: 190px;
  position: relative;
  background-image: url(${BannerWithDraw});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36px;

  @media only screen and (max-width: 560px) {
    justify-content: center;
    border-radius: 10px;

    img {
      display: none;
    }
  }

  @media only screen and (max-width: 560px) {
    background-image: url(${BannerMobile});
  }
`;

export const TitleTop = styled.h1`
  font-family: "Devator";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0.1em;
  font-feature-settings: "salt" on, "liga" off;
  background: linear-gradient(90.35deg, #ffffff -3.34%, #a7a7a7 104.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const BoxBody = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
`;

export const BoxCard = styled.div`
  width: 100%;
  height: 100%;
  min-height: 490px;
  display: inline-flex;
  justify-content: center;
`;
export const Card = styled.div`
  width: 422px;
  /* height: 490px; */
  background: #040410;
  border: 1px solid #006438;
  border-radius: 16px;
  padding: 24px 22px;

  position: relative;
  abbr {
    width: 100%;
    text-decoration: none;
  }
  :after {
    position: absolute;
    content: "";
    top: 72px;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #006437;
  }
  :before {
    position: absolute;
    content: "";
    top: 72px;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    width: 22%;
    height: 2px;
    background-color: #01fe8f;
    z-index: 2;
  }

  @media only screen and (max-width: 560px) {
    width: 350px;
    height: 490px;
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
    height: 490px;
  }
`;

export const TitleSmall = styled.h1<{bg?:any}>`
  font-family: ClashDisplayMedium;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 150%;
  text-align: center;
  :nth-child(1) {
    background: transparent;
  }
  height: 24px;
  background-image: url(${({bg})=> bg || arrowDubble});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ButtonNew = styled(Button)`
  font-family: ClashDisplayMedium;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1.5px solid #01ec85;
  border-radius: 16px;
  position: relative;
  z-index: 20;

  :hover {
    color: #4eff00;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #4eff00;
  }

  :disabled {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #4eff00;
    color: #ffffff;
    cursor: no-drop !important;
  }
  :active {
    box-shadow: 4px 4px 6px 0 rgba(78, 255, 0, 0.3),
      -4px -4px 6px 0 rgba(78, 255, 0, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;
