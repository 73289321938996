import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const TeleIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M7.83469 13.1963L7.52399 18.79C7.97895 18.79 8.18979 18.5387 8.44501 18.2347L10.6532 15.6824L15.2472 19.7289C16.0906 20.2843 16.7009 19.9934 16.9117 18.79L19.93 1.8501C20.2407 0.369015 19.4639 -0.305408 18.6539 0.130983L0.932634 8.27695C-0.276892 8.85881 -0.265795 9.66547 0.710703 9.99608L5.26029 11.7151L15.7799 3.79403C16.2792 3.43697 16.7342 3.63534 16.3569 4.04528L7.83469 13.1963Z"
        fill="white"
      />
    </Svg>
  );
};

export default TeleIcon;
