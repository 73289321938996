import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: ClashDisplay;
  }
  body {
    overflow-x: hidden;
    z-index: 2;
    width: 100%;
    background-size: 1440px 934px;
    background-color: #06061D;
    color: #fff;
  
    img {
      height: auto;
      max-width: 100%;
    }
  }
  body::-webkit-scrollbar{
    width: 0px;
  }
  iframe {
    display: none;
  }

  li {
      list-style-type: none
    }
`;

export default GlobalStyle;
