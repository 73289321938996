import { Box ,  Flex  } from "@raca2022/uikit";
import styled from "styled-components";

export const Wapper = styled(Box)`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0px 7.9vw;
  @media only screen and (min-width: 1440px) {
    padding: 0px 115px;
  }
  @media only screen and (max-width: 743px) {
    padding: 0px 2.67vw;
  }
`;


export const Container = styled(Box)`
  width: 100%;
  max-width: 1215px;
  min-height: 62vh;
  margin: auto;
  :before {
    position: absolute;
    content: "";
    top: 84px;
    left: 0px;
    width: 100%;
    height: 20.833333333vw;
    max-height: 300px;
    background: linear-gradient( #06061d, #06061d, #06061d, #06061d, #06061d, transparent );
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: -1;
  }
  @media only screen and (max-width: 1240px) {
    padding: 0px 10px;
    padding-top: 20px;
  }
  @media only screen and (max-width: 767px) {
    :before {
      top: 64px;
    }
  }
`;
export const Title = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  height: 4.65625vw;
  max-height: 67.05px;
  align-items: center;
  .pagination{
    display: flex;
    gap: 0.5vw;
    position: relative;
    li{
      a{
        :after{
          display: none;
        }
        :before{
          display: none;
        }
      }
    }
    :after{
      height:100%;
      width: 1px;
      background-color: #fff;
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h1 {
    font-size: 1.666666666vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    font-weight: 700;
    font-family: SPHEREFEZ;
    background: linear-gradient(90.23deg, #a9a9a9 0%, #9b9b9b 102.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  p {
    font-size: 1.111111111vw;
    font-weight: 700;
    letter-spacing: 0.03em;
    span {
      :nth-child(1) {
        font-weight: 500;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 14.057786666vw;
    span {
      display: none;
    }
    h1 {
      font-size: 14px;
    }
    p {
      font-size: 16px;
    }
  }
`;

export const FlexBoxPagi = styled(Flex)`
  position: relative;
  .cus-table-font {
    font-family: ClashDisplayBold;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    white-space: nowrap;
  }

  .page-item.active {
    /* position: absolute;
    width: 40px;
    height: 40px;
    left: -14%; */
    display: none;
  }

  .page-link:focus {
    background-color: none;
    box-shadow: none;
  }

  .page-item:last-child .page-link {
    position: relative;
    background: transparent;
    border: none;
    color: #fff;

    ::before {
      position: absolute;
      content: "";
      width: 1.5px;
      height: 60%;
      background-color: #c4c4c4;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .page-item:first-child .page-link {
    position: relative;
    background: transparent;
    border: none;

    ::after {
      position: absolute;
      content: "";
      width: 1.5px;
      height: 60%;
      background-color: #c4c4c4;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  @media only screen and (max-width: 375px) {
    .cus-table-font {
      font-size: 4.26vw;
    }
  }
  @media only screen and (max-width: 640px){
    .cus-table-font {
      :nth-child(4) , :nth-child(5) {
        display: none;
      }
    }
  }
`;