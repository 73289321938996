import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Providers from "./Provider";
import reportWebVitals from "./reportWebVitals";
import App from "./view";

import "./index.css";
import "slick-carousel/slick/slick.css";
import { LoginProvider } from "./components/provider/LoginProvider";
// import { WagmiConfig, createConfig, configureChains} from 'wagmi'
// import { mainnet } from 'wagmi/chains'
 
// import { alchemyProvider } from 'wagmi/providers/alchemy'
// import { publicProvider } from 'wagmi/providers/public'
 
// import { InjectedConnector } from 'wagmi/connectors/injected'
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
 
// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [mainnet],
//   [publicProvider()],
// )
 
// const config = createConfig({
//   autoConnect: true,
//   connectors: [
//     new MetaMaskConnector({ chains }),
//     new WalletConnectConnector({   
//       chains,
//       options: {
//         projectId: '...',   
//       },
//     }),   
//     new InjectedConnector({
//       chains,
//       options: {
//         name: 'Injected',
//         shimDisconnect: true,
//       },
//     }),
//   ],
//   publicClient,
//   webSocketPublicClient,
// })

ReactDOM.render(
  <React.StrictMode>
    {/* <WagmiConfig config={config}> */}
    <Providers>
      <BrowserRouter>
        <LoginProvider>
          <App />
        </LoginProvider>
      </BrowserRouter>
    </Providers>
    {/* </WagmiConfig> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//
