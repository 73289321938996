import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const CoppyIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <g clip-path="url(#clip0_12_30568)">
        <path
          d="M4.16675 12.5003C3.39018 12.5003 3.00189 12.5003 2.69561 12.3735C2.28723 12.2043 1.96277 11.8798 1.79362 11.4715C1.66675 11.1652 1.66675 10.7769 1.66675 10.0003V4.33366C1.66675 3.40024 1.66675 2.93353 1.8484 2.57701C2.00819 2.2634 2.26316 2.00844 2.57676 1.84865C2.93328 1.66699 3.39999 1.66699 4.33341 1.66699H10.0001C10.7767 1.66699 11.1649 1.66699 11.4712 1.79386C11.8796 1.96302 12.2041 2.28747 12.3732 2.69585C12.5001 3.00214 12.5001 3.39042 12.5001 4.16699M10.1667 18.3337H15.6667C16.6002 18.3337 17.0669 18.3337 17.4234 18.152C17.737 17.9922 17.992 17.7372 18.1518 17.4236C18.3334 17.0671 18.3334 16.6004 18.3334 15.667V10.167C18.3334 9.23357 18.3334 8.76686 18.1518 8.41034C17.992 8.09674 17.737 7.84177 17.4234 7.68198C17.0669 7.50033 16.6002 7.50033 15.6667 7.50033H10.1667C9.23333 7.50033 8.76662 7.50033 8.4101 7.68198C8.09649 7.84177 7.84153 8.09674 7.68174 8.41034C7.50008 8.76686 7.50008 9.23357 7.50008 10.167V15.667C7.50008 16.6004 7.50008 17.0671 7.68174 17.4236C7.84153 17.7372 8.09649 17.9922 8.4101 18.152C8.76662 18.3337 9.23333 18.3337 10.1667 18.3337Z"
          stroke="#F2F2F2"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_30568">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default CoppyIcon;
