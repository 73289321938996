import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Router, useLocation, Route, BrowserRouter } from "react-router-dom";
import { ResetCSS, Box } from "@raca2022/uikit";
import Aos from "aos";
// import { ToastContainer } from "react-hot-toast";
import { Toaster } from "react-hot-toast";

import { Button } from "../components/Button";
import { ScrollToTop } from "../components/ScrollTop";
import { Menu } from "../components/Menu";
import Footer from "../components/FooterQuataHome";
import Loading from "../components/Loading/LoadingDesktop";
import RedirectPathToHome from "./redirects";

import history from "../routerHistory";
import GlobalStyle from "../style";
import TabHistory from "./Swap/chilrend/TabHistory";
import "aos/dist/aos.css";
import Transfer from "./transfer";
import BuyToken from "./BuyToken";

//
const Home = lazy(() => import("./Home"));
const Dashboard = lazy(() => import("./Dashboard"));
const Investment = lazy(() => import("./Investment"));
const History = lazy(() => import("./History"));
const Affiliates = lazy(() => import("./affiliates"));
const Swap = lazy(() => import("./Swap"));
const Withdraw = lazy(() => import("./Withdraw"));
const Ecosystem = lazy(() => import("./Ecosystem"));
const TestInvesment = lazy(() => import("./TestInvesment/TestInvesment"));
const Farm = lazy(() => import("./Farm"));

const App = () => {
  Aos.init();
  return (
    <> 
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          {/* @ts-ignore */}
          <ResetCSS />
          {/* @ts-ignore */}
          <GlobalStyle />
          <Menu />
          {/* <Social /> */}
          <Box className="overFlow cus-pt-global" overflowX="hidden">
            <ScrollToTop>
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/dashboard" exact>
                  <Dashboard />
                </Route>
                {/* <Route path="/trade" exact>
                  <Swap />
                </Route> */}
                <Route path="/history">
                  <TabHistory />
                </Route>
                <Route path="/staking" exact>
                  <Investment />
                </Route>
                {/* <Route path="/transfer" exact>
                  <Transfer/>
                </Route> */}
                {/* <Route path="/transaction" exact>
                  <History />
                </Route> */}
                <Route path="/affiliates" exact>
                  <Affiliates />
                </Route>
                {/* <Route path="/swap" exact>
                  <Swap />
                </Route> */}
                <Route path="/withdraw" exact>
                  <Withdraw />
                </Route>
                <Route path="/farm" exact>
                  <Farm />
                </Route>
                <Route path="/buy-xe" exact>
                  <BuyToken/>
                </Route>
                {/* <Route component={RedirectPathToHome} /> */}
              </Switch>
            </ScrollToTop>
          </Box>
          <Footer />
        </Router>
      </Suspense>

      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default App;
