import { Box, Flex } from "@raca2022/uikit";
import styled from "styled-components";
import XGreen from "../../assets/Footer/X.svg";

export const FootterAndOutTeam = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 36px;
  max-width: 1920px;
  margin: auto;
  padding-top: 40px;

  @media only screen and (max-width: 744px) {
    padding: 0 10px;
  }
`;
export const FooterAndOutTeamContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
export const Outteam = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const OutteamFloor = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1348px;
  padding: 0 46px;
  @media only screen and (max-width: 769px) {
    padding: 0 12px;
  }
`;
export const Title = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0 46px;
  padding-top: 50px;
  h1 {
    font-size: 48px;
    font-family: Devantor;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: 0 12px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 640px) {
    h1 {
      font-size: 30px;
    }
  }
`;
export const Teams = styled(Box)`
  width: 23%;
  max-width: 323px;
  height: 157px;
  border-radius: 8px;
  padding-top: 45px;
  padding-left: 25px;
  position: relative;
  p {
    font-size: 10px;
    font-family: Monda;
    font-weight: 400;
    line-height: 17px;
    margin-top: 10px;
  }
  h1 {
    font-size: 16px;
    font-family: Monda;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
  }
  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
export const FootterFootball = styled(Flex)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  /* padding-top: 20px; */
  padding-bottom: 40px;

  .view_sp {
    font-family: ClashDisplayRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #92a4ad;
    margin-top: 18px;
  }

  .view_coppy {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #92a4ad;
    font-family: ClashDisplayRegular;
    margin-top: 20px;
  }

  @media only screen and (max-width: 980px) {
    flex-direction: column;

    &:nth-child(2) {
      width: 100%;
    }
  }
`;
export const FooterItem = styled(Box)`
  width: 19%;
  gap: 10px;
  position: relative;
  h1 {
    font-family: ClashDisplayRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #dce6e6;
    padding-bottom: 8px;
    position: relative;
  }
  p {
    font-family: ClashDisplayRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #92a4ad;
  }
  img {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.3s linear;
  }

  @media only screen and (max-width: 768px) {
    width: 50%;
    margin-bottom: 30px;
    padding-left: 30px;
    h1,
    p {
      text-align: center;
    }
  }
  @media only screen and (max-width: 475px) {
    width: 100%;
    h1,
    p {
      text-align: left;
    }
  }
`;

export const SOCIAL = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 28px;
  flex-wrap: wrap;

  @media only screen and (max-width: 500px) {
    gap: 0;
    row-gap: 30px;

    & > * {
      width: 25%;
      text-align: center;
    }
  }

  img {
    max-width: 34px;
    max-height: 27px;
    /* margin-right: 30px; */
    :last-child {
      margin: 0px;
    }
  }
`;
export const CoppyRight = styled(Box)`
  width: 100%;
  padding-top: 30px;
  /* padding-bottom: 26px; */
  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Monda;
    font-weight: 400;
    line-height: 21px;
    color: #cdcec5;
    margin-bottom: 12px;
  }
`;
export const FooterLogo = styled(Box)`
  width: 100%;
  margin-right: 60px;
  img {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
    /* padding-bottom: 60px; */
  }
`;

export const BoxIconText = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  div {
    border-radius: 50%;
    :first-child {
      width: 35px;
      height: 35px;
      background-color: #000;
      background-image: url("${XGreen}");
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 10px;
    }
    :last-child {
      width: 24px;
      height: 24px;
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  p {
    font-weight: 600;
    font-size: 12.6px;
    line-height: 13px;
    color: #ecf1f0;
    margin-right: 10px;
  }
`;

export const FooterConten = styled(Flex)`
  padding-left: 172px;
  flex: 1;
  align-items: flex-start;

  @media only screen and (max-width: 1440px) {
    flex-wrap: wrap;
    padding-left: 80px;
  }

  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding-left: 0px;
  }

  @media only screen and (max-width: 980px) {
    width: 100%;
    padding-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media only screen and (max-width: 475px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const FooterItemElm = styled(Box)<{ height?: string }>`
  height: fit-content;
  p {
    cursor: pointer;
  }
  a {
    font-family: ClashDisplayRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #92a4ad;
    padding-bottom: 8px;
  }
  @media only screen and (max-width: 475px) {
    height: ${({ height }) => height};
    overflow: hidden;
    transition: all 0.3s linear;
  }
`;
