import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer, TextStatus } from "./style";
// import { Loading } from "../../../components/Loading";

interface PropsTransition {
  data: any[];
  checkLoading?: boolean;
}

const Table: React.FC<PropsTransition> = ({ data, checkLoading }) => {
  // console.log('data',data)
  return (
    <TableContainer>
      <table>
        <tr>
          <th className="no">No</th>
          <th>ID</th>
          <th>Amount</th>
          <th>Description</th>
          <th>FromUser</th>
          <th>StrCreateOn</th>
          <th>Status Name</th>
        </tr>

        {data?.map((item: any, index: number) => {
          try {
            return (
              <tr key={item?.Id}>
                <td>{index + 1}</td>
                <td>{item?.Id}</td>
                <td>{item?.Amount}</td>
                <td>{item?.Description}</td>
                <td>{`${item?.FromUser?.substring(0, 4)}...${item?.FromUser?.substring(
                  item?.FromUser.length - 4
                )}`}</td>
                <td>{item?.StrCreateOn}</td>
                <td>{item?.StatusName}</td>
              </tr>
            );
          } catch (error) {
            console.log(error);
          }
        })}
      </table>
    </TableContainer>
  );
};
export default Table;
