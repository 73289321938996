import styled from "styled-components";
import { ButtonV1, Box, Heading, Text, Flex } from "@raca2022/uikit";
// import HoverConnect from "../../assets/hoverConnect.png";

export const BoxModal = styled(Box)`
  width: 100%;
  max-width: 401px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  background-color: #24272a;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .cus-box-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media only screen and (max-width: 570px) {
    margin-top: 40px;
    max-width: 350px;
    min-width: 350px;
  }

  .none-pb {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BoxPd = styled(Box)`
  /* padding: 10px; */
  padding-top: 0;
  padding-bottom: 50px;
  position: relative;

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const BoxPdDisconnect = styled(Box)`
  /* padding: 10px; */
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  z-index: 11;

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .text-custom {
    font-family: Monda !important;
    color: #fff;
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;
    background-color: transparent;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    gap: 14px;
    cursor: pointer;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
    height: 62px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const BoxModalContent = styled(Box)`
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > * {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
  }

  .wallet-flex {
    transition: 0.3s ease-in-out;
    padding: 5px 8px;
    min-width: 358px;
    border: 1px solid #01f78b;
    border-radius: 10px;
    background: transparent;
    transition: 0.3s ease;
    min-height: 62px;
    height: 100%;

    .cus-arrow {
      opacity: 0;
      visibility: hidden;
    }

    :hover {
      background-color: #ea6e6e;
      border: none;

      .item-text {
        color: #671b1b !important;
      }

      .cus-arrow {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media only screen and (max-width: 570px) {
    & > * {
      width: 100%;
    }
    .wallet-flex {
      min-width: 280px;
    }
  }
`;

export const BoxModalDisConnect = styled(Box)`
  flex-direction: column;
  min-width: 401px;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow: hidden;
  & > * {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .btn-connect {
    margin: 0 20px;
    width: 165px;
    height: 50px;
    background: linear-gradient(267.66deg, rgb(200, 255, 77) 0.33%, rgb(154, 222, 0) 98.08%);
    border: 2px solid rgb(229, 255, 171);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;

    :hover {
      background: #ea6e6e;
      color: #671b1b;
    }
  }

  .noti-out-text {
    font-weight: 500;
    text-align: center;
    font-size: 22px;
    color: #fffdfd;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 570px) {
    min-width: auto;
  }
`;

export const ModalText = styled(Text)`
  font-family: SPHEREFEZ;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #ffffff;
  transform: rotate(0.21deg);

  @media only screen and (max-width: 570px) {
    font-size: 24px;
  }
`;

export const BoxHeader = styled(Flex)`
  padding: 24px 16px 24px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .BoxImgPopUpDisConnect{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 90%;
    }
  }
  button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 12px;
  }

  @media only screen and (max-width: 570px) {
    padding: 12px;
  }
`;
