import React from "react";
import { Box, BoxProps } from "@raca2022/uikit";

const Container: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    pl={["10px", "10px", "30px", "30px"]}
    pr={["10px", "10px", "30px", "30px"]}
    mx="auto"
    maxWidth="1440px"
    width="100%"
    height="100%"
    {...props}
  >
    {children}
  </Box>
);

export default Container;
