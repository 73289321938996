import { Box } from "@raca2022/uikit";
import styled from "styled-components";

// export const TableContainer = styled(Box)<{ status?: number }>`
//   width: 100%;
//   margin-bottom: 5vw;
//   table {
//     width: 100%;
//     height: 100%;
//     background-color: #1a1a1a;
//     border-radius: 10px;
//     position: relative;

//     tr {
//       position: relative;
//     }

//     td.cus-loading {
//       width: 100% !important;
//       position: absolute !important;
//       padding: 0;
//       display: inline-flex;
//       justify-content: center;
//       align-items: center;

//       :after {
//         display: none;
//       }
//     }

//     tr {
//       height: 3.75vw;
//       border: 0.516828px solid rgba(255, 255, 255, 0.05);
//       th {
//         font-size: 0.972222222vw;
//         font-weight: 700;
//         text-transform: uppercase;
//         font-family: ClashDisplayMedium;
//         text-align: left;
//         :nth-child(1) {
//           text-align: center;
//           line-height: 3.75vw;
//         }
//         :last-child {
//           padding-left: 2%;
//           text-align: center;
//         }
//       }
//       td {
//         font-size: 0.972222222vw;
//         font-weight: 500;
//         font-family: ClashDisplayMedium;
//         color: #b6b6b6;
//         :nth-child(1) {
//           text-align: center;
//           line-height: 3.75vw;
//         }
//         :last-child {
//           color: #000000;
//           padding-left: 2%;
//           position: relative;
//           z-index: 1;
//           // :after {
//           //   content: "";
//           //   width: 55%;
//           //   aspect-ratio: 1/0.33070868745;
//           //   position: absolute;
//           //   background: linear-gradient(97.09deg, #702121 0.49%, #d76262 99.93%);
//           //   left: 0%;
//           //   top: 50%;
//           //   transform: translateY(-50%);
//           //   border-radius: 10%/30.3%;
//           //   z-index: -1;
//           // }
//         }
//       }
//     }
//     :first-child {
//       border-radius: 10px 10px 0px 0px;
//       th {
//         :first-child {
//           border-radius: 10px 0px 0px 0px;
//         }
//         :last-child {
//           border-radius: 0px 10px 0px 0px;
//         }
//       }
//     }
//     :last-child {
//       td {
//         :first-child {
//           border-radius: 0px 0px 10px 0px;
//         }
//         :last-child {
//           border-radius: 0px 0px 0px 10px;
//         }
//       }
//     }
//   }
//   @media only screen and (min-width: 1440px) {
//     table {
//       tr {
//         height: 54px;
//         th {
//           font-size: 14px;
//           :nth-child(1) {
//             line-height: 54px;
//           }
//         }
//         td {
//           font-size: 14px;
//           :nth-child(1) {
//             line-height: 54px;
//           }
//         }
//       }
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     table {
//       tr {
//         height: 28.066666666vw;
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         justify-content: center;
//         position: relative;
//         margin-bottom: 1.066666666vw;
//         :first-child {
//           display: none;
//         }
//         td {
//           position: absolute;
//           :first-child {
//             display: none;
//           }
//           :nth-child(2) {
//             display: none;
//           }
//           :nth-child(3) {
//             font-size: 5.333333333vw;
//             line-height: 7.466666666vw;
//             top: 17.43119266%;
//             left: 3.418803418%;
//           }
//           :nth-child(4) {
//             font-size: 3.733333333vw;
//             top: 58.229357798%;
//             left: 3.418803418%;
//           }
//           :nth-child(5) {
//             font-size: 3.2vw;
//             top: 28.862385321%;
//             right: 8.262108262%;
//           }
//           // :nth-child(6) {
//           //   font-size: 3.733333333vw;
//           //   top: 48.458715596%;
//           //   left: 3.418803418%;
//           // }
//           :last-child {
//             position: absolute;
//             font-size: 3.733333333vw;
//             right: 8.262108262%;
//             top: 52.467889908%;
//             p {
//               padding: 1.35vw;
//               width: 20.556vw;
//             }
//             :after {
//               content: "";
//               position: absolute;
//               width: 110%;
//               left: 0%;
//               top: 45%;
//             }
//           }
//         }
//       }
//       th {
//         display: none;
//       }
//     }
//   }
// `;

export const TextStatus = styled.p<{ status?: any }>`
  width: fit-content;
  padding: 0.35vw 0.5vw;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 5.555555555vw;
  background: ${({ status }) =>
    status !== "0"
      ? "#01F78B"
      : "linear-gradient(97.09deg,#702121 0.49%,#d76262 99.93%);"};
  border-radius: 10%/30%;
  margin: auto;
  @media only screen and (min-width: 1440px) {
    width: 80px;
  }
  
`;

export const TableContainer = styled(Box)`
  width: 100%;
  margin-bottom: 5vw;
  table {
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    border-radius: 10px;
    position: relative;

    tr {
      position: relative;
    }

    td.cus-loading {
      width: 100% !important;
      position: absolute !important;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      :after {
        display: none;
      }
    }

    tr {
      height: 3.75vw;
      border: 0.516828px solid rgba(255, 255, 255, 0.05);
      th {
        font-size: 0.972222222vw;
        font-weight: 700;
        text-transform: uppercase;
        font-family: OpenSansMedium;
        min-width: 2vw;
        :nth-child(1) {
          text-align: center;
          line-height: 3.75vw;
        }
        :last-child {
          padding-left: 2%;
        }
      }
      td {
        font-size: 0.972222222vw;
        font-weight: 500;
        font-family: OpenSansMedium;
        color: #b6b6b6;
        text-align: center;
        :nth-child(1) {
          text-align: center;
          line-height: 3.75vw;
        }
        :last-child {
          color: #ffffff;
          padding-left: 2%;
          position: relative;
          z-index: 1;
          // :after {
          //   content: "";
          //   width: 55%;
          //   aspect-ratio: 1/0.33070868745;
          //   position: absolute;
          //   background: linear-gradient(97.09deg, #702121 0.49%, #d76262 99.93%);
          //   left: 0%;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   border-radius: 10%/30.3%;
          //   z-index: -1;
          // }
          p {
            width: fit-content;
            padding: 0.35vw 0.5vw;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            width: 5.555555555vw;
            border-radius: 10%/30%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            @media only screen and (min-width: 1440px) {
              width: 80px;
            }
          }
        }
      }
    }
    :first-child {
      border-radius: 10px 10px 0px 0px;
      th {
        :first-child {
          border-radius: 10px 0px 0px 0px;
        }
        :last-child {
          border-radius: 0px 10px 0px 0px;
        }
      }
    }
    :last-child {
      td {
        :first-child {
          border-radius: 0px 0px 10px 0px;
        }
        :last-child {
          border-radius: 0px 0px 0px 10px;
        }
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    table {
      tr {
        height: 54px;
        th {
          font-size: 14px;
          :nth-child(1) {
            line-height: 54px;
          }
        }
        td {
          font-size: 14px;
          :nth-child(1) {
            line-height: 54px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    table {
      tr {
        height: 28.066666666vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        margin-bottom: 1.066666666vw;
        :first-child {
          display: none;
        }
        td {
          position: absolute;
          :first-child {
            display: none;
          }
          :nth-child(2) {
            display: none;
          }
          :nth-child(3) {
            font-size: 5.333333333vw;
            line-height: 7.466666666vw;
            top: 17.43119266%;
            left: 3.418803418%;
          }
          :nth-child(4) {
            font-size: 3.733333333vw;
            top: 69.229357798%;
            left: 3.418803418%;
          }
          :nth-child(5) {
            font-size: 3.2vw;
            top: 28.862385321%;
            right: 8.262108262%;
          }
          :nth-child(6) {
            font-size: 3.733333333vw;
            top: 48.458715596%;
            left: 3.418803418%;
          }
          :last-child {
            position: absolute;
            font-size: 3.733333333vw;
            right: 8.262108262%;
            top: 52.467889908%;
            p {
              padding: 1.35vw;
              width: 20.556vw;
              transform: translateX(-95%) translateY(0%);
            }
            :after {
              content: "";
              position: absolute;
              width: 110%;
              left: 0%;
              top: 45%;
            }
          }
        }
      }
      th {
        display: none;
      }
    }
  }
`;
