import styled from "styled-components";
import { TableMobileWapper } from "../../components/tableCommon/tableMobile";

export const BuyWapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 70px 0px;
`;
export const BuyContainer = styled.div`
  width: 100%;
  max-width: 1210px;
  margin: 44px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${TableMobileWapper} {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    .Tabble-Wapper{
        display: none;
    }
    ${TableMobileWapper} {
      display: flex;
    }
  }
`;
export const BoxStyled1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  &:last-child {
    padding-bottom: 16px;
  }
  p,
  span {
    font-size: 16px;
    line-height: 20px;
  }
  p {
    color: #c1c1c1;
    border-bottom: 1px dashed #828282;
  }
`;

export const TextTile = styled.h3`
  font-size: 12px;
  font-weight: 300;
  span {
    color: #05b46b;
  }
`;

export const TextMax = styled.h6`
  font-size: 10px;
  font-weight: 300;
  span {
    color: #05b46b;
  }
`;
export const TextDes = styled.p`
  padding: 5px 15px;
  border-radius: 5px;
  background: green;
  width: fit-content;
  margin: auto;
`;
