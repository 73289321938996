import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Heading, Box, Flex, Text } from "@raca2022/uikit";
import Arow from "../../assets/Footer/arrow.svg";
import {
  FootterAndOutTeam,
  FooterAndOutTeamContainer,
  Outteam,
  Title,
  Teams,
  FootterFootball,
  FooterItem,
  SOCIAL,
  CoppyRight,
  OutteamFloor,
  FooterLogo,
  FooterConten,
  FooterItemElm,
  BoxIconText,
} from "./style";

import {
  LogoXfunderIcon,
  DiscordIcon,
  TelegramIcon,
  TwitterIcon,
} from "../Svg";

// import Img from "../../assets/imgQuata/Home/Outteam.png";
import Logo from "../../assets/imgQuata/Logo/Logo.png";
import img1 from "../../assets/imgQuata/socical/TLG.svg";
import img2 from "../../assets/imgQuata/socical/socical.svg";
import img3 from "../../assets/imgQuata/socical/twitter.svg";
import img4 from "../../assets/imgQuata/socical/intergram.svg";
import img5 from "../../assets/imgQuata/socical/youtube.svg";
import img6 from "../../assets/imgQuata/socical/Media.svg";
import img7 from "../../assets/imgQuata/socical/FB.svg";
import img8 from "../../assets/imgQuata/socical/Cus.svg";
import ArrowDown from "../../assets/imgQuata/socical/arrowDown.svg";
import { useSelector } from "react-redux";

const ArrSociical = [
  {
    url: "https://t.me/footdexofficial",
    src: img1,
  },
  {
    url: "https://discord.com/invite/qXPScXpjsK",
    src: img2,
  },
  {
    url: "https://twitter.com/footballdex",
    src: img3,
  },
  {
    url: "https://www.instagram.com/footdex.io/",
    src: img4,
  },
  {
    url: "https://www.youtube.com/channel/UCyeK1Y1VMpfvscSyusoT6Og",
    src: img5,
  },
  {
    url: "https://footdex.medium.com/",
    src: img6,
  },
  {
    url: "https://www.facebook.com/footdex.io",
    src: img7,
  },
  {
    url: "https://footdex.substack.com/",
    src: img8,
  },
];

interface PropsSection {
  tokennomics?: any;
  roadmap?: any;
  coreTeam?: any;
}

const ScrollToSection = (props: any) => {
  props.current.scrollIntoView({ behavior: "smooth" });
};

const Footter: React.FC<PropsSection> = ({
  tokennomics,
  roadmap,
  coreTeam,
}) => {
  const [showECOSYSTEM, setShowECOSYSTEM] = useState(false);
  const [showECONOMY, setShowECONOMY] = useState(false);
  const [showTEAM, setShowTEAM] = useState(false);
  const [showABOUT, setShowABOUT] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const { Error401, listGetPrice }: any = useSelector<any>(
    (state) => state.auth
  );
  return (
    <FootterAndOutTeam>
      <>
        <FootterFootball>
          <Box>
            <FooterLogo>
              <LogoXfunderIcon width="170px" />
            </FooterLogo>
            <div>
              <p className="view_sp">support@xfunder.io</p>
            </div>
            <BoxIconText>
              <Box />
              <p>{listGetPrice || '--'}</p>
              <Box>
                <img src={Arow} />
              </Box>
            </BoxIconText>
            <div>
              <p className="view_coppy">XFUNDER © 2023</p>
            </div>
          </Box>
          <FooterConten>
            <FooterItem>
              <h1
                onClick={() => {
                  setShowECOSYSTEM(!showECOSYSTEM);
                }}
              >
                Products
                {window.innerWidth < 476 ? (
                  <img
                    src={ArrowDown}
                    alt=""
                    style={{
                      transform: `rotateZ(${showECOSYSTEM ? "180" : "0"}deg)`,
                    }}
                  />
                ) : (
                  <></>
                )}
              </h1>
              <FooterItemElm
                height={
                  window.innerWidth < 476 && showECOSYSTEM ? "100px" : "0px"
                }
              >
                <p>
                  <a href="/">Perpetual</a>
                </p>
                <p>
                  <a href="/">Staking</a>
                </p>
              </FooterItemElm>
            </FooterItem>
            <FooterItem>
              <h1
                onClick={() => {
                  setShowECONOMY(!showECONOMY);
                }}
              >
                Service
                {window.innerWidth < 476 ? (
                  <img
                    src={ArrowDown}
                    alt=""
                    style={{
                      transform: `rotateZ(${showECONOMY ? "180" : "0"}deg)`,
                    }}
                  />
                ) : (
                  <></>
                )}
              </h1>
              <FooterItemElm
                height={
                  window.innerWidth < 476 && showECONOMY ? "100px" : "0px"
                }
              >
                <p onClick={() => ScrollToSection(tokennomics)}>App Download</p>
                <p onClick={() => ScrollToSection(roadmap)}>Affiliate</p>
              </FooterItemElm>
            </FooterItem>
            <FooterItem>
              <h1
                onClick={() => {
                  setShowTEAM(!showTEAM);
                }}
              >
                Support
                {window.innerWidth < 476 ? (
                  <img
                    src={ArrowDown}
                    alt=""
                    style={{
                      transform: `rotateZ(${showTEAM ? "180" : "0"}deg)`,
                    }}
                  />
                ) : (
                  <></>
                )}
              </h1>
              <FooterItemElm
                height={window.innerWidth < 476 && showTEAM ? "100px" : "0px"}
              >
                <a href="https://docs.xfunder.ai/" target="_blank">
                  Docs
                </a>
                <p>API</p>
                <p onClick={() => ScrollToSection(coreTeam)}>Fees</p>
              </FooterItemElm>
            </FooterItem>
            <FooterItem>
              <h1
                onClick={() => {
                  setShowABOUT(!showABOUT);
                }}
              >
                About Us
                {window.innerWidth < 476 ? (
                  <img
                    src={ArrowDown}
                    alt=""
                    style={{
                      transform: `rotateZ(${showABOUT ? "180" : "0"}deg)`,
                    }}
                  />
                ) : (
                  <></>
                )}
              </h1>
              <FooterItemElm
                height={window.innerWidth < 476 && showABOUT ? "100px" : "0px"}
              >
                <p>
                  <a href="/" target="_blank" rel="noreferrer">
                    Announcements
                  </a>
                </p>
                <p>
                  <a href="/" target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                </p>
                <p>
                  <a href="/" target="_blank" rel="noreferrer">
                    Privacy
                  </a>
                </p>
              </FooterItemElm>
            </FooterItem>
            <FooterItem>
              <h1
                onClick={() => {
                  setShowIcon(!showIcon);
                }}
              >
                Community
                {window.innerWidth < 476 ? (
                  <img
                    src={ArrowDown}
                    alt=""
                    style={{
                      transform: `rotateZ(${showIcon ? "180" : "0"}deg)`,
                    }}
                  />
                ) : (
                  <></>
                )}
              </h1>
              <FooterItemElm
                height={window.innerWidth < 476 && showIcon ? "100px" : "0px"}
              >
                <Flex
                  justifyContent={[
                    "center",
                    "center",
                    "flex-start",
                    "flex-start",
                  ]}
                  style={{ gap: "16px" }}
                >
                  <p>
                    <a href="/" target="_blank" rel="noreferrer">
                      <TwitterIcon />
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://discord.com/channels/1078895088341631006/1081091868571480135"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DiscordIcon />
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://t.me/xfunderofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TelegramIcon />
                    </a>
                  </p>
                </Flex>
              </FooterItemElm>
            </FooterItem>
          </FooterConten>
        </FootterFootball>
        {/* <SOCIAL>
          {ArrSociical.map((_item, _index) => (
            <Box key={_index}>
              <a href={_item.url} rel="noreferrer" target="_blank">
                <img src={_item.src} alt={`cus-${_index}`} />
              </a>
            </Box>
          ))}
        </SOCIAL>
        <CoppyRight>
          <p>User Agreement</p>
          <p>Copyright © 2022 FOOTBALL</p>
        </CoppyRight> */}
      </>
    </FootterAndOutTeam>
  );
};
export default Footter;
