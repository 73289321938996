import React, { useState } from "react";
import styled from "styled-components";
import { Wapper, Container, Title, FlexBoxPagi } from "./style";
import { Box, Flex, Text } from "@raca2022/uikit";
import Table from "./table";
import ReactPaginate from "react-paginate";
import useTrasaction from "../../History/useTransaction";

const TabHistory = () => {
  const { setIsPage, isPage, isList, total, totalPage,checkLoading } = useTrasaction();
  const [isNumTotalRender, setIsNumTotalRender] = useState(1);
  const [isNumTotalRenderV1, setIsNumTotalRenderV1] = useState(10);

  const handlePageClick = (event: any) => {
    let numsPage = event.selected;
    let numsPages = event.selected + 1;
    setIsPage((prev: any) => ({ ...prev, pageIndex: numsPage.toString() }));
    setIsNumTotalRender(numsPage * 10);
    setIsNumTotalRenderV1(numsPages * 10);
  };
  return (
    <Wapper>
      <Container>
        <Title>
          <h1>History</h1>
          <Flex width={"50%"} justifyContent={"flex-end"}>
            <FlexBoxPagi>
              <Text className="cus-table-font" color="#fff">
                {isNumTotalRender === 1
                  ? isNumTotalRender
                  : isNumTotalRender + 1}
              </Text>
              <Text className="cus-table-font" color="#fff" pr="4px">
                -
              </Text>
              <Text className="cus-table-font" color="#fff">
                {isNumTotalRenderV1}
              </Text>
              <Text className="cus-table-font" color="#fff" px="8px">
                in number
              </Text>
              <Text className="cus-table-font" color="#fff" pr="8px">
                {/* {total} */}
                {totalPage}
              </Text>
               {/* @ts-ignore */}
              <ReactPaginate
              pageCount={Math.ceil(total / 10)}
              pageRangeDisplayed={0}
              marginPagesDisplayed={0}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              pageClassName="page-item"
              breakClassName="page-item"
              nextClassName="page-item"
              previousClassName="page-item"
              previousLabel={<>&laquo;</>}
              nextLabel={<>&raquo;</>}
            />
            </FlexBoxPagi>
          </Flex>
        </Title>
        <Table checkLoading={checkLoading} data={isList} />
      </Container>
    </Wapper>
  );
};

export default TabHistory;