import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useLayoutEffect,
  } from "react";
  import { useLogin, useActiveWeb3React } from "../../hooks";
  import { TOKEN } from "../../redux/settings/config";
  import { useLocation } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import { actLoginAdmin } from "../../redux/action/AuthManagerAction";
  import styled from "styled-components";
  import { actGetPriceToken } from "../../redux/action/AuthManagerApi";
  import changeNetwork from "../Connect/funcChanngeNetword";
  
  export const LoginProvider: React.FC = ({ children }) => {
    const { account } = useActiveWeb3React();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const chainId = process.env.REACT_APP_CHAIN_ID ?? "0";
    const [values, setValues]: any = useState({
      Address: "",
      Signature: "",
      ReferalCode: "",
    });
    const [currentChainId, setCurrentChainId]: any = useState<any>(null);
    const params = new URLSearchParams(window.location.search);
    const foo = params.get("referral"); // bar
    const { Error401, listGetPrice }: any = useSelector<any>(
      (state) => state.auth
    );
    useMemo(() => {
      if (account) {
        setValues((prev: any) => ({ ...prev, Address: account }));
      }
      if (search.indexOf("?referral=") !== -1) {
        setValues((prev: any) => ({ ...prev, ReferalCode: foo }));
      }
    }, [account, search]);
    const _isLogin = (values: any) => {
      if (values.Address !== "") {
        dispatch(actLoginAdmin(values));
      }
    };
  
    useEffect(() => {
      dispatch(actGetPriceToken());
      if (window.ethereum) {
        window.ethereum.on("chainChanged", (netWordId :any) => {
          setCurrentChainId(netWordId)
        });
      }
    }, []);
    useLayoutEffect(() => {
      if (account && localStorage.getItem("adress") !== account) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("isCheck");
        localStorage.removeItem("adress");
      }
    }, [account]);
    console.log(Error401)
    useEffect(() => {
      if (
        !localStorage.getItem(TOKEN)!! ||
        (Error401?.response && Error401?.response?.status === 401) ||
        !localStorage.getItem("adress") ||
        localStorage.getItem("adress") !== account
      ) {
        if (account) {
          _isLogin(values);
        }
      }
    }, [account, Error401]);
    useEffect(() => {
      // if (account) {
        if (window.ethereum) {
          if (chainId != window.ethereum.networkVersion) { 
            changeNetwork(`0x${parseInt(chainId).toString(16)}`);
          }
        }
      // }
    }, [account , currentChainId]);
  
    const isConnectVeri = window.localStorage.getItem("accountStatus");
  
    const isLogout = (isConnectVeri: any) => {
      return isConnectVeri === null;
    };
  
    useEffect(() => {
      (async () => {
        if (isLogout(isConnectVeri)) {
          localStorage.removeItem("jwt");
          localStorage.removeItem("isCheck");
          localStorage.removeItem("adress");
        }
      })();
    }, [isConnectVeri]);
  
    localStorage.setItem("price", listGetPrice);
    return <LoginParent>{children}</LoginParent>;
  };
  const LoginParent = styled.div``;
  